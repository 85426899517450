import { View } from 'native-base';
import React, { useEffect, useMemo, useState } from 'react';
import { FreeDeliverySVG } from '../../constants/imgs';
import Image from "./customImg";
import { Text } from './custom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Platform, SafeAreaView } from 'react-native';
import { CheckStoreClosed, GetOrderType } from '../../helpers/helpers';
export function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedTime = [hours, minutes, remainingSeconds]
        .map((time) => String(time).padStart(2, '0'))
        .join(':');

    return formattedTime;
}

const ClosedBanner = () => {
    const receipt = useSelector(state => state.order.receipt, shallowEqual);

    const items = useSelector(state => state.order?.items, shallowEqual);
    const location = useSelector(state => state.order?.location, shallowEqual);
    const { strings } = useSelector(state => state.locale, shallowEqual)
    const allLocations = useSelector(state => state.settings.allLocations, shallowEqual)
    const busy = useSelector(state => state.settings.busy, shallowEqual)
    const orderType = useSelector(state => state.settings.orderType, shallowEqual)

    const [state, setState] = useState(false);
    const dispatch = useDispatch();

    const Busy = useMemo(() => {
        if (busy) {
            if (busy?.branches?.includes(location?.branch)) {
                return {
                    type: 'branch',
                    message: strings?.["Sorry we cannot accept orders at the moment"]
                }
            }
            if (busy?.[orderType]?.includes(location?.branch)) {
                return {
                    type: 'orderType',
                    message: strings?.[`Sorry we cannot accept ${orderType} orders at the moment`]
                }
            }

            if (busy?.[receipt?.orderType === 'schaduled' ? 'groceries' : 'restaurant']?.includes(location?.branch)) {
                return {
                    type: 'orderType',
                    message: strings?.[`Some items in your cart from the ${receipt?.orderType === 'schaduled' ? 'groceries' : 'restaurant'} category are currently unavailable and need to be removed before placing the order.`]

                }
            }

            if (busy?.[location?.type]?.includes(location?.branch)) {
                return {
                    type: 'location',
                    message: strings?.["Sorry we cannot accept pickup orders at the moment"]
                }
            }
        }
        return false

    }, [busy, location, orderType, receipt])

    const Closed = useMemo(() => {
        if (location?.type === 'delivery') {
            let type = GetOrderType(items)
            switch (type) {
                case 'mix': case 'sameDay':
                    let closedUntil = dispatch(CheckStoreClosed());
                    return closedUntil
                default:
                    return 0
            }
        } else {
            return 0
        }

    }, [items, location, allLocations])


    useEffect(() => {
        if (Busy) {
            return setState('busy')
        }
        if (Closed) {
            return setState(Closed)
        }
        return setState(0)

    }, [Closed, Busy])

    useEffect(() => {
        let interval;
        if (state) {
            if (state === 'busy') {
                return
            }
            interval = setInterval(() => {
                setState(state - 1)
            }, 1000);
        }
        if (state === 0) {
            clearInterval(interval)
        }
        return () => {
            clearInterval(interval)
        }
    }, [state])
    if (!state) {
        return null

    }

    return (
        <SafeAreaView style={{ backgroundColor: '#E0262D30', paddingTop: Platform.OS === 'android' ? 25 : 0 }}>
            <View style={{
                paddingHorizontal: 16,
                paddingVertical: 12,
                // backgroundColor: 'rgba(255, 137, 0, 0.05)',
                borderColor: 'rgba(182,147,41)',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>

                <Image
                    fill='rgba(224, 38, 45, 1)'
                    svg
                    width={25}
                    height={25}
                    uri={FreeDeliverySVG}
                />
                <View style={{ marginHorizontal: 15 }}>
                    <Text style={{
                        color: '#000',
                        textAlign: 'left',
                        fontSize: 12,
                    }}>{Busy ? (Busy?.message || strings?.["Sorry we cannot accept orders at the moment"]) : strings?.["Sorry we cannot accept FAST DELIVERY orders at the moment"]}</Text>

                    {/* <Text style={{
                        color: '#000',
                        textAlign: 'left',
                        fontSize: 14,
                        fontFamily: 'semiBold',
                    }}>{Busy? '' : `${strings?.["You can place your order after"]}  ${formatTime(state)}`}</Text> */}

                </View>
            </View>
        </SafeAreaView>

    );
};

export default ClosedBanner;