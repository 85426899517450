import React, { useEffect, useState } from "react";
import { Provider, useSelector } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "./routes";
import { Platform, View } from "react-native";
import taybaLogo from './assets/logoTayba.png'
import AsyncStorageStatic from '@react-native-async-storage/async-storage';
import { NativeBaseProvider, extendTheme, StatusBar, Spinner } from 'native-base';
import { requestTrackingPermission } from 'react-native-tracking-transparency';

import AlertContainer from "./component/shared/AlertContainer";
import ClosedBanner from "./component/shared/ClosedBanner";
import { requestTrackingPermission as TrackingPermision } from "./helpers/index";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { REACT_APP_STRIPE } from "./constants/variables/index";
const stripePromise = loadStripe(REACT_APP_STRIPE);
const newColorTheme = {
  primary: {
    50: '#2F8AF5',
    100: '#2F8AF5',
    200: '#2F8AF5',
    300: '##2F8AF5',
    400: '#2F8AF5',
    500: '#2F8AF5',
    600: '#2F8AF5',
    700: '#2F8AF590',
    800: '#2F8AF590',
    900: '#2F8AF590',
  },
};
const theme = extendTheme({ colors: newColorTheme });
const LoadingContainer = ({ }) => {
  const { loading } = useSelector(state => state.settings)
  return loading ? <View
    position={'absolute'}
    backgroundColor='#00000050'
    zIndex={999999999}
    height={'100%'}
    width='100%'
    justifyContent='center'
    alignItems={'center'}
    flex={1}>
    <Spinner size={'lg'} />
  </View> : <View />

}
function App() {

  const [language, setLanguage] = useState('')
  useEffect(() => {
    setTimeout(() => {
      requestTrackingPermission().then((trackingStatus) => {
        console.log("trackingStatustrackingStatus", trackingStatus)
        TrackingPermision()
      });
    }, 1000);
  }, [])
  useEffect(() => {

    (async () => {
      let lang = await AsyncStorageStatic.getItem('language')
      setLanguage(lang)
    })()

  }, [])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <NativeBaseProvider theme={theme}  >
          <View style={{ backgroundColor: "#fff" }}>
            <ClosedBanner />
          </View>

          <Elements stripe={stripePromise} >
            <View style={{ flexDirection: 'row', height: '100%' }}>
              <Routes />
              <div
                className='emptySpace'
                style={{
                  backgroundImage: 'url("https://firebasestorage.googleapis.com/v0/b/tayba-2b919.appspot.com/o/img400.jpg?alt=media&token=70b0bfcd-f3bf-48d4-869b-0faa655f577a")',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',

                }} >
                <div style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'rgb(0, 0, 0,0.5)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }} >
                  <div
                    style={{
                      width: '300px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: "center",
                      alignItems: 'center'
                    }} >
                    <img style={{ width: '250px', height: '250px' }} src={taybaLogo} alt="" />
                    <div style={{
                      fontSize: '18px',
                      color: 'white',
                      textAlign: 'center',
                      fontFamily: 'bold'
                    }} >{
                        language === 'en' ? 'Tayba Gourmet' : 'طيبة جورميه'
                      }</div>
                    <div style={{
                      marginTop: '8px',
                      fontSize: '14px',
                      color: 'white',
                      textAlign: 'center',
                    }}>
                      {language === 'en' ? 'Scan code and download the app for better experience on mobile' : 'امسح الكود وقم بتنزيل التطبيق للحصول على تجربة أفضل على هاتفك'}

                    </div>
                  </div>
                </div>

              </div>
            </View>
          </Elements>
        </NativeBaseProvider>
      </PersistGate>
    </Provider>
  )

}

export default App;